import React, { useState, useEffect } from "react";
import Layout from "../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"

import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../assets/images/market_ui/slack/blog_image.png");
const image1 = require("../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../assets/images/market_ui/slack/img_4.png");

const BlogHeader = require("../../assets/img/blogs/header_blog_images_9.png.webp");
const Section1 = require("../../assets/img/blogs/chatbot_best_1.png");
const Section2 = require("../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../assets/img/blogs/chatbot_best_5.png");
const Section6 = require("../../assets/img/blogs/chatbot_best_6.png");
const Section7 = require("../../assets/img/blogs/chatbot_best_7.png");
const Section8 = require("../../assets/img/blogs/chatbot_best_8.png");
const SectionLast = require("../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Learn about workativ assistant - conversational ai platform | Workativ Blog "
        description="Workativ Assistant is a cognitive workplace automation suite designed to transform the way companies provide workplace IT support to employees."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Workativ Assistant: A Conversational AI & Automation
                            Platform for innovative workplace solutions
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <h6 className="font-section-sub-header-small">
                          Workativ Assistant 1.0
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          In today’s modern workplace environment, providing a
                          great workplace IT support helps define a positive
                          culture of an organization. Once your employees are
                          given proper support, the productivity increases,
                          naturally. In a traditional IT support model,
                          employees spend a lot of time writing lengthy emails
                          or making long calls with the IT Helpdesk. Common
                          support issues such as repairing or replacing an
                          employee asset, adding colleagues to the distribution
                          list, onboarding new users and providing access to
                          business applications, both users and Helpdesk are
                          left with a pile of support requests backlogs causing
                          a serious dip in productivity. Even retrieving a
                          document or a simple password reset can be time
                          consuming taking a nose dive in productivity. Managing
                          an IT Helpdesk can cost a lot of money to companies as
                          companies spend an average of $14 per support ticket
                          or call to Helpdesk, without any ROI. And often
                          putting IT managers under endless performance pressure
                          as well. The ultimate challenge for companies today is
                          how to digitize this legacy approach using latest
                          technologies, provide support anytime and anywhere to
                          users, freeing up expensive IT resources, and all this
                          whilst lowering the cost. The task seems impossible.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 class="font-section-sub-header-small">
                          What is Workativ Assistant?
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a cognitive workplace automation
                          suite designed to transform the way companies provide
                          workplace IT support to employees. Workativ Assistant
                          helps companies purpose build an efficient Virtual
                          Assistant with RPA capabilities to resolve repetitive
                          workplace issues and service requests.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Key aspects of Workativ Assistant are,
                        </p>
                        <ul class="font-section-small-text">
                          <li class="font-section-small-text">
                            Companies can rapidly design, deploy, and train
                            Virtual Assistants via simple, easy to understand
                            visual interface.
                          </li>
                          <li class="font-section-small-text">
                            Provides a powerful IT Process editor to
                            conceptualize, build and execute robust IT
                            workflows.
                          </li>
                          <li class="font-section-small-text">
                            Virtual assistants can be readily deployed on
                            familiar conversational channels such as slack,
                            skype or even on the self-service portals.
                          </li>
                          <li class="font-section-small-text">
                            Workativ Assistant brings great flexibility to the
                            table for IT teams by automating time-consuming
                            workplace process and by letting end users get
                            appropriate workplace support from anywhere and at
                            any time.
                          </li>
                        </ul>
                        <h6 class="font-section-sub-header-small-bold mt-5 mb-5">
                          <a href="https://workativ.com/conversational-ai-platform/features">
                            {" "}
                            Workativ Assistant capabilities
                          </a>
                        </h6>
                        <h6 class="font-section-sub-header-small">
                          Simple Easy to Use interface (EUS)
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A simple and easy to use user interface to build
                          intricate IT process that involves several components
                          such as forms, approval, authentication, ticket
                          creation, etc. Designed for IT teams to work towards
                          employee/user satisfaction without facing any design
                          difficulties.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Automation Catalogue
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          A rich catalogue with pre-defined automation workflows
                          for low-coders to easily moderate and execute common
                          IT process. Built for IT teams to get started with
                          workplace automation right away instead of building
                          workflows/process from scratch.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Conversational forms
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Obtain valuable data from end user in a more
                          compelling and conversational way. Create engaging
                          forms that drive users to provide information you
                          require to offer seamless workplace support.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Cognitive + RPA in Days
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Build RPA induced Virtual Assistants in days and
                          transform your existing IT support to a digital model.
                          Make the workplace support experience seamless for
                          users by proactively resolving workplace issues in a
                          more intelligent way. Focus more on issue resolution
                          and not just chatting.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Flexible Digital Experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Integrate Workativ Assistant with popular digital
                          channels for users to get instant workplace support
                          right from any location at any time. Without any
                          external support, users can quickly launch their
                          favorite app such as Slack or Skype to report their
                          workplace issues, follow up and getting it resolved.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Automation Creator
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Custom build workflows using an intuitive workflow
                          creator integrate with all the core business systems
                          and to carry out end-to-end automation. Save time by
                          continually analyzing and optimizing the workflows to
                          improve the support experience.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Dialogue Creator
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Design logic-driven dialogues for users to raise and
                          resolve workplace issues in a more conversational way.
                          Recognize user intents with our intelligent intent
                          recognition feature and turn any complex interactions
                          and decision trees into human-like conversations.
                        </p>

                        <h6 class="font-section-sub-header-small-bold mt-5 mb-5">
                          Supercharging Benefits
                        </h6>

                        <h6 class="font-section-sub-header-small">
                          Self-Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Users can{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            self-resolve IT related workplace issues{" "}
                          </a>
                          by instantly initiating a chat with Virtual Assistant.
                          Instead of browsing the entire knowledge base, or
                          creating a IT Helpdesk ticket manually, virtual
                          assistant can now resolve common issues or address any
                          service requests without any human intervention.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          IT Productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Free up your IT team by
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            {" "}
                            automating redundant, time-consuming workplace
                            process{" "}
                          </a>
                          and let them focus on building or managing mission
                          critical components of the business. Help IT team
                          enhance their productivity by taking the workplace
                          support operation burden off their shoulder using{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            AI and Automation.
                          </a>
                        </p>
                        <h6 class="font-section-sub-header-small">
                          User Productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          By leveraging Virtual Assistant, employees can save up
                          to 20 minutes per day on an average connecting with IT
                          Helpdesk. Have employees focus more on business tasks
                          and innovation.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Digital Experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          The flexibility to getting your workplace IT support
                          will enhance the overall workplace experience for end
                          users. Identify channels such as slack or skype for
                          business that are prominent within your user group,
                          and start servicing them right away.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Improve SLAs
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          By offering right service to your employees at the
                          right time, IT teams can constantly render satisfying
                          workplace support to employees which in turn helps
                          them exceed service management SLAs. Take the pressure
                          of your service management team.
                        </p>
                        <h6 class="font-section-sub-header-small">
                          Lower your Helpdesk Costs
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          On an average,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/cost-reduction">
                            save up to 40% of your IT Helpdesk spending{" "}
                          </a>
                          and increase your overall ROI by replacing labor
                          intensive processes with cognitive automation
                        </p>
                        <h6 class="font-section-sub-header-small">
                          24/7 Support
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Providing IT Helpdesk after office hours are even more
                          expensive and more over your employees should not be
                          left behind after work hours for IT support. Using
                          Virtual Assistant, you can provide round-the-clock
                          support to your employees at a fraction of cost.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Ready to transform your workplace support? You have
                          come to the right place.
                        </p>
                      </div>

                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                              Conversational AI Chatbot for employee service automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              What Is Conversational AI? A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="https://workativ.com/conversational-ai-platform">
                        <img src={topImage} alt="slack chatbot" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <OnScrollPopup />
            <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://workativ.com/conversational-ai-platform"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};

const HelpdeskWrapper = (props) => {
  return (
    <div className="nocode_wrapper helpdesk_wrapper">
      <h4>
        <span>
          <img src={require("../../assets/images/arrow_blog.svg")} alt="img" />
        </span>
        {props.header}
      </h4>
    </div>
  );
};
